import { h, createApp, reactive } from "vue";
import singleSpaVue from "single-spa-vue";
import router from "./router";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import AutoComplete from "primevue/autocomplete";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Avatar from "primevue/avatar";
import AvatarGroup from "primevue/avatargroup";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import Button from "primevue/button";
import Breadcrumb from "primevue/breadcrumb";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Carousel from "primevue/carousel";
import Chart from "primevue/chart";
import Checkbox from "primevue/checkbox";
import Chip from "primevue/chip";
import Chips from "primevue/chips";
import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";
import ContextMenu from "primevue/contextmenu";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Fieldset from "primevue/fieldset";
import FileUpload from "primevue/fileupload";
import Galleria from "primevue/galleria";
import Image from "primevue/image";
import InlineMessage from "primevue/inlinemessage";
import Inplace from "primevue/inplace";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Knob from "primevue/knob";
import Listbox from "primevue/listbox";
import MegaMenu from "primevue/megamenu";
import Menu from "primevue/menu";
import Menubar from "primevue/menubar";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import OrderList from "primevue/orderlist";
import OrganizationChart from "primevue/organizationchart";
import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import Password from "primevue/password";
import PickList from "primevue/picklist";
import ProgressBar from "primevue/progressbar";
import Rating from "primevue/rating";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import SelectButton from "primevue/selectbutton";
import ScrollPanel from "primevue/scrollpanel";
import ScrollTop from "primevue/scrolltop";
import Slider from "primevue/slider";
import Sidebar from "primevue/sidebar";
import Skeleton from "primevue/skeleton";
import SpeedDial from "primevue/speeddial";
import SplitButton from "primevue/splitbutton";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Steps from "primevue/steps";
import StyleClass from "primevue/styleclass";
import TabMenu from "primevue/tabmenu";
import Tag from "primevue/tag";
import TieredMenu from "primevue/tieredmenu";
import Textarea from "primevue/textarea";
import Timeline from "primevue/timeline";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Toolbar from "primevue/toolbar";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Tooltip from "primevue/tooltip";
import ToggleButton from "primevue/togglebutton";
import Tree from "primevue/tree";
import TreeSelect from "primevue/treeselect";
import TreeTable from "primevue/treetable";
import TriStateCheckbox from "primevue/tristatecheckbox";

// coisas do index.html

require("@/../public/theme/denim/theme-light.css");
require("@/../public/layout/css/layout-light.css");
require("@/main.ts");

// URLs of the resources to load
const urls = [
  "https://fonts.googleapis.com",
  "https://fonts.gstatic.com",
  "https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200",
  "https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700&display=swap",
  "https://fonts.googleapis.com/css2?family=Material+Icons",
  "https://kit.fontawesome.com/961845dcc3.js",
  "https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js",
  "https://www.google.com/recaptcha/api.js",
];

// Function to load a CSS file
function loadCSS(url: string) {
  let link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = url;
  document.head.appendChild(link);
}

// Function to load a JavaScript file
function loadJS(url: string) {
  let script = document.createElement("script");
  script.src = url;
  document.body.appendChild(script);
}

// Load the resources
for (let url of urls) {
  if (url.endsWith(".js")) {
    loadJS(url);
  } else {
    loadCSS(url);
  }
}

//import CodeHighlight from '@/components/AppCodeHighlight';
//import BlockViewer from '@/components/BlockViewer.vue';

import "@/assets/styles.scss";
import i18n from "./i18n";
// import { createGtm } from "@gtm-support/vue-gtm";
import { NhostClient } from "@nhost/vue";
import { createApolloClient } from "@nhost/apollo";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { abilitiesPlugin } from "@casl/vue";
import { createDynamicForms } from "@mathmore0000/vue-dynamic-forms";
import { ability } from "./service/ability";
import { updateRole } from "@/service/roles";

import { createPinia } from "pinia";
let apolloClient;
let toastG;
let useGlobalStore;
let AppLayout;
let nhost;
const module = "due_diligence";

// highlight-start
// highlight-end

const vueLifecycles = (singleSpaVue as any)({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      });
    },
  },
  async handleInstance(app) {
    await importSharedDependencies(app);

    const pinia = createPinia();
    const VueDynamicForms = createDynamicForms();

    // @ts-ignore
    const { subdomain, region } = await import("components/env");
    nhost = new NhostClient({
      subdomain,
      region,
    });

    const r = await updateRole(
      createApolloClient({ nhost }),
      (await nhost.auth.isAuthenticatedAsync()) ? nhost.auth.getUser().id : null
    );
    apolloClient = createApolloClient({
      nhost,
      headers: { "x-hasura-role": r },
    });

    app.config.globalProperties.$appState = reactive({
      isRTL: false,
      isNewThemeLoaded: false,
      layoutMode: "light",
    });

    app.use(PrimeVue, { ripple: true });
    app.use(ConfirmationService);
    app.use(ToastService);
    toastG = app.config.globalProperties.$toast;

    app.use(pinia);

    app.directive("tooltip", Tooltip);
    app.directive("ripple", Ripple);
    //app.directive('code', CodeHighlight);
    app.directive("badge", BadgeDirective);
    app.directive("styleclass", StyleClass);

    app.component("Accordion", Accordion);
    app.component("AccordionTab", AccordionTab);
    app.component("AutoComplete", AutoComplete);
    app.component("Avatar", Avatar);
    app.component("AvatarGroup", AvatarGroup);
    app.component("Badge", Badge);
    app.component("Breadcrumb", Breadcrumb);
    app.component("Button", Button);
    app.component("Calendar", Calendar);
    app.component("Card", Card);
    app.component("Carousel", Carousel);
    app.component("Chart", Chart);
    app.component("Checkbox", Checkbox);
    app.component("Chip", Chip);
    app.component("Chips", Chips);
    app.component("ColorPicker", ColorPicker);
    app.component("Column", Column);
    app.component("ConfirmDialog", ConfirmDialog);
    app.component("ConfirmPopup", ConfirmPopup);
    app.component("ContextMenu", ContextMenu);
    app.component("DataTable", DataTable);
    app.component("DataView", DataView);
    app.component("DataViewLayoutOptions", DataViewLayoutOptions);
    app.component("Dialog", Dialog);
    app.component("Divider", Divider);
    app.component("Dropdown", Dropdown);
    app.component("Fieldset", Fieldset);
    app.component("FileUpload", FileUpload);
    app.component("Galleria", Galleria);
    app.component("Image", Image);
    app.component("InlineMessage", InlineMessage);
    app.component("Inplace", Inplace);
    app.component("InputMask", InputMask);
    app.component("InputNumber", InputNumber);
    app.component("InputSwitch", InputSwitch);
    app.component("InputText", InputText);
    app.component("Knob", Knob);
    app.component("Listbox", Listbox);
    app.component("MegaMenu", MegaMenu);
    app.component("Menu", Menu);
    app.component("Menubar", Menubar);
    app.component("Message", Message);
    app.component("MultiSelect", MultiSelect);
    app.component("OrderList", OrderList);
    app.component("OrganizationChart", OrganizationChart);
    app.component("OverlayPanel", OverlayPanel);
    app.component("Paginator", Paginator);
    app.component("Panel", Panel);
    app.component("PanelMenu", PanelMenu);
    app.component("Password", Password);
    app.component("PickList", PickList);
    app.component("ProgressBar", ProgressBar);
    app.component("RadioButton", RadioButton);
    app.component("Rating", Rating);
    app.component("SelectButton", SelectButton);
    app.component("ScrollPanel", ScrollPanel);
    app.component("ScrollTop", ScrollTop);
    app.component("Slider", Slider);
    app.component("Sidebar", Sidebar);
    app.component("Skeleton", Skeleton);
    app.component("SpeedDial", SpeedDial);
    app.component("SplitButton", SplitButton);
    app.component("Splitter", Splitter);
    app.component("SplitterPanel", SplitterPanel);
    app.component("Steps", Steps);
    app.component("TabMenu", TabMenu);
    app.component("TabView", TabView);
    app.component("TabPanel", TabPanel);
    app.component("Tag", Tag);
    app.component("Textarea", Textarea);
    app.component("TieredMenu", TieredMenu);
    app.component("Timeline", Timeline);
    app.component("Toast", Toast);
    app.component("Toolbar", Toolbar);
    app.component("ToggleButton", ToggleButton);
    app.component("Tree", Tree);
    app.component("TreeSelect", TreeSelect);
    app.component("TreeTable", TreeTable);
    app.component("TriStateCheckbox", TriStateCheckbox);

    //app.component('BlockViewer', BlockViewer);

    app
      // highlight-start
      .use(nhost)
      // highlight-end
      //.use(stores)
      .use(router)
      .use(i18n)
      .provide(DefaultApolloClient, apolloClient)
      .use(VueDynamicForms);

    app.use(abilitiesPlugin, ability);

    // if (import.meta.env.VITE_APP_GTM_ENABLED) {
    //   app.use(
    //     createGtm({
    //       id: import.meta.env.VITE_APP_GTM_KEY,
    //       debug: false,
    //       vueRouter: router,
    //     })
    //   );
    // }
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

export async function importSharedDependencies(app) {
  try {
    // @ts-ignore
    const { pinia } = await import("components/main");

    // @ts-ignore
    const { default: applyt } = await import("components/AppLayout");
    AppLayout = applyt;
    // @ts-ignore
    const { default: AppTopBar } = await import("components/AppTopBar");

    // @ts-ignore
    const { default: AppMenu } = await import("components/AppMenu");

    // @ts-ignore
    const { default: AppSubmenu } = await import("components/AppSubmenu");

    // @ts-ignore
    const { default: AppFooter } = await import("components/AppFooter");

    // @ts-ignore
    const { default: AppConfig } = await import("components/AppConfig");

    // @ts-ignore
    const { default: AppRightPanel } = await import("components/AppRightPanel");

    const { default: AppRightAppBreadcrumbPanel } = await import(
      // @ts-ignore
      "components/AppBreadcrumb"
    );

    // @ts-ignore
    const { useGlobalStore: useGS } = await import("components/global-store");
    useGlobalStore = useGS;

    app.use(pinia);
    app.component("AppTopBar", AppTopBar);
    app.component("AppMenu", AppMenu);
    app.component("AppSubmenu", AppSubmenu);
    app.component("AppFooter", AppFooter);
    app.component("AppConfig", AppConfig);
    app.component("AppRightPanel", AppRightPanel);
    app.component("AppLayout", AppLayout);
    app.component("AppRightAppBreadcrumbPanel", AppRightAppBreadcrumbPanel);
  } catch (error) {
    console.error("Failed to import shared dependencies:", error);
    throw error; // You can handle or propagate the error as needed
  }
}

function updateApolloClientByRole(r) {
  apolloClient = createApolloClient({
    nhost,
    headers: { "x-hasura-role": r },
  });
}

export {
  updateApolloClientByRole,
  toastG,
  apolloClient,
  useGlobalStore,
  AppLayout,
  nhost,
  module,
};
