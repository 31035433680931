import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-62690d6b"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "loading-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ProgressSpinner = _resolveComponent("ProgressSpinner");
  return _ctx.globalStore.loadingController ? (_openBlock(), _createElementBlock("main", _hoisted_1, [_createVNode(_component_ProgressSpinner, {
    style: {
      "width": "70px",
      "height": "70px"
    },
    strokeWidth: "4"
  })])) : _createCommentVNode("", true);
}