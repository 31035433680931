import Button from "primevue/button";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { role } from "@/service/roles";
import { nhost, module } from "../main";
import { isUserBlocked } from "@/service/blocking";
import { updateRole } from "@/service/roles";
import { useRequestStore } from "@/stores/request-store";

import AppLayout from "@/layout/AppLayout.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "notFound" },
  },
  {
    path: "/",
    redirect: { name: "home" },
  },
  {
    meta: {
      requiresAuth: true,
      requiresPickedTenant: true,
    },
    path: "/",
    children: [
      {
        meta: {
          allowedRoles: [
            "tenant_admin_dd",
            "tenant_admin_dd_bay",
            "dd-manager",
            "dd-viewer",
          ],
        },
        path: "/",
        component: AppLayout,
        children: [
          {
            path: "/requests",
            name: "requests",
            children: [
              {
                path: "home",
                name: "home",
                component: () => import("@/views/pages/home/Home.vue"),
              },
              {
                meta: {
                  allowedRoles: [
                    "tenant_admin_dd",
                    "tenant_admin_dd_bay",
                    "dd-manager",
                  ],
                },
                path: "create",
                name: "create",
                component: () => import("@/views/pages/request/Request.vue"),
              },
              {
                meta: {
                  allowedRoles: [
                    "tenant_admin_dd",
                    "tenant_admin_dd_bay",
                    "dd-manager",
                  ],
                },
                path: "request-detail/:id",
                name: "request-detail",
                component: () => import("@/views/pages/request/Request.vue"),
              },
            ],
          },
          {
            path: "/instructions-for-use",
            name: "instructions-for-use",
            component: () =>
              import(
                "@/views/pages/instructions-for-use/InstructionsForUse.vue"
              ),
          },
          {
            meta: {
              allowedRoles: ["tenant_admin_dd", "tenant_admin_dd_bay"],
            },
            path: "settings",
            children: [
              {
                path: "",
                name: "settings",
                component: () => import("@/views/pages/settings/Dashboard.vue"),
              },
              {
                path: "user",
                name: "user",
                component: () =>
                  import("@/views/pages/settings/cruds/User.vue"),
              },
              {
                path: "typology",
                name: "typology",
                component: () =>
                  import("@/views/pages/settings/cruds/Typology.vue"),
              },
              {
                path: "business-unit",
                name: "businessUnit",
                component: () =>
                  import("@/views/pages/settings/cruds/BusinessUnit.vue"),
              },
              {
                path: "periocity",
                name: "periocity",
                component: () =>
                  import("@/views/pages/settings/cruds/Periocity.vue"),
              },
              {
                path: "block-reason",
                name: "blockReason",
                component: () =>
                  import("@/views/pages/settings/cruds/BlockReason.vue"),
              },
              {
                path: "treatment",
                name: "treatment",
                component: () =>
                  import("@/views/pages/settings/cruds/Treatment.vue"),
              },
              {
                path: "entry-channel",
                name: "entryChannel",
                component: () =>
                  import("@/views/pages/settings/cruds/EntryChannel.vue"),
              },
              {
                path: "supplier",
                name: "supplier",
                component: () =>
                  import("@/views/pages/settings/cruds/Supplier.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    component: AppLayout,
    path: "/",
    children: [
      {
        path: "/access-denied-m",
        name: "access-denied-m",
        component: () => import("@/components/errors/AccessDeniedM.vue"),
      },
    ],
  },
  {
    path: "/errors",
    children: [
      {
        path: "/not-found",
        name: "notFound",
        component: () => import("@/components/errors/NotFound.vue"),
        props: {
          Button,
        },
      },
      {
        path: "/under-construction",
        name: "underConstruction",
        component: () => import("@/components/errors/UnderConstruction.vue"),
        props: {
          Button,
        },
      },
      {
        component: AppLayout,
        path: "/",
        children: [
          {
            path: "/access-denied",
            name: "access-denied",
            component: () => import("@/components/errors/AccessDenied.vue"),
            props: {
              Button,
            },
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  //await nhost.auth.refreshSession()
  // @ts-ignore
  const { useGlobalStore } = await import("components/global-store");
  const globalStore = useGlobalStore();
  globalStore.hideLoading();

  const requiresAuth = to.meta.requiresAuth;
  const requiresNotAuth = to.meta.requiresNotAuth;
  const requiresNotReg = to.meta.requiresNotReg;
  const requiresPickedTenant = to.meta.requiresPickedTenant;
  const allowedRoles = to.meta.allowedRoles as string[];
  const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
  nhost.auth.refreshSession();
  const isReg =
    nhost.auth.getHasuraClaim("x-hasura-Tenant-User-Id") ||
    nhost.auth.getHasuraClaim("x-hasura-Tenant-ADM-Id");
  const hasPickedTenant = !!nhost.auth.getHasuraClaim("x-hasura-Tenant-Now-Id");

  if (["access-denied-m", "access-denied"].includes(to.name.toString()))
    return next();
  if (requiresAuth && !isAuthenticated) {
    window.location.href = "/auth#/login";
    return;
  }
  const role = await updateRole();
  if (requiresPickedTenant && !hasPickedTenant) {
    const encodedRedirect = encodeURIComponent(
      `/modules/due-diligence#${to.fullPath as string}`
    );
    window.location.href = `/auth#/setup/setup-choice${encodedRedirect}`;
    return;
  }
  if (await isUserBlocked()) {
    next({ name: "access-denied-m" });
    return;
  }
  if (allowedRoles && !allowedRoles.includes(role as string)) {
    next({ name: "access-denied" });
    return;
  }
  if (
    ["create", "request-detail"].includes(to.name as string) &&
    allowedRoles &&
    !allowedRoles.includes(role as string)
  ) {
    next({ name: "home" });
    return;
  }
  const isSecurityRoute = useRequestStore().$state.goBackSecurity;
  if (from.name == to.name || isSecurityRoute) return next();
  if (["create", "request-detail"].includes(from.name as string)) {
    useRequestStore().setDialogVisibleTrue();
    return next(false); // Cancel the navigation
  }

  next();
});

export default router;
